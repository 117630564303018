<template>
	<div class="details">
		<div class="alert">
			<alert
				:title="state === 0 ? '审核中' : state === 1 ? '审核通过' : '审核不通过'"
				:type="state === 0 ? 'info' : state === 1 ? 'success' : 'error'"
				align="center"
				:sub-title="state === 2 ? '原因: ' + teacherInfo.failed_reason : ''"
			>
			</alert>
		</div>
		<t-card
			style="border-top: 1rem solid #e8edff"
			title="基础信息"
			:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
		>
			<div class="block">
				<div class="avatar">
					<el-image
						style="width: 100%; height: 100%"
						:src="!teacherInfo.avator_text ? headPortrait : teacherInfo.avator_text"
					></el-image>
				</div>
				<div class="item">
					<div class="key">姓名 :</div>
					<div class="val">{{ teacherInfo.clerk_name }}</div>
				</div>
				<div class="item">
					<div class="key">性别 :</div>
					<div class="val">{{ teacherInfo.gender }}</div>
				</div>
				<div class="item">
					<div class="key">出生日期 :</div>
					<div class="val">{{ teacherInfo.birthday }}</div>
				</div>
				<div class="item">
					<div class="key">毕业院校 :</div>
					<div class="val">{{ teacherInfo.graduated_from }}</div>
				</div>
				<div class="item">
					<div class="key">最高学历 :</div>
					<div class="val">{{ teacherInfo.education }}</div>
				</div>
				<div class="item">
					<div class="key">所学专业 :</div>
					<div class="val">{{ teacherInfo.major }}</div>
				</div>
				<div class="item">
					<div class="key">联系电话 :</div>
					<div class="val">{{ teacherInfo.mobile }}</div>
				</div>
			</div>
		</t-card>
		<t-card
			style="border-top: 1rem solid #e8edff"
			title="入职信息"
			:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
		>
			<div class="block">
				<div class="item">
					<div class="key">一级科目 :</div>
					<div class="val">{{ teacherInfo.fist_subject_text }}</div>
				</div>
				<div class="item">
					<div class="key">二级科目 :</div>
					<div class="val">{{ teacherInfo.subject_text }}</div>
				</div>
				<div class="item">
					<div class="key">入职时间 :</div>
					<div class="val">{{ teacherInfo.entry_time }}</div>
				</div>
				<div class="item">
					<div class="key">是否在职 :</div>
					<div class="val">{{ parseInt(teacherInfo.clerk_status) === 1 ? '在职' : '离职' }}</div>
				</div>
			</div>
		</t-card>

		<t-card
			style="border-top: 1rem solid #e8edff"
			title="个人简介"
			:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
		>
			<div class="block">
				<div class="item">
					<div class="key">优势标签 :</div>
					<div class="val">
						<el-tag
							effect="dark"
							style="margin-right: 15rem; background-color: #e8edff; color: #666; border: none"
							v-for="item in teacherInfo.tags"
							>{{ item }}</el-tag
						>
					</div>
				</div>
				<div class="item">
					<div class="key">个人简历 :</div>
					<div class="val">{{ teacherInfo.summary }}</div>
				</div>
				<div class="item">
					<div class="key">个人照片 :</div>
					<div class="val">
						<el-image style="width: 186rem; height: 247rem" :src="!teacherInfo.photo_text  ? imgUrl : teacherInfo.photo_text"></el-image>
					</div>
				</div>
				<div class="item">
					<div class="key">审核状态 :</div>
					<div class="val">{{ state === 0 ? '待审核' : state === 1 ? '审核通过' : '审核不通过' }}</div>
				</div>
			</div>
		</t-card>
		<div class="button">
			<el-button type="primary" v-if="state === 0" @click="passDialog = true">通过</el-button>
			<el-button type="primary" v-if="state === 1 || state === 2" @click="$router.back()">返回</el-button>
			<el-button @click="onReject" v-if="state !== 2">驳回</el-button>
		</div>
		<!--  通过提示弹窗  -->
		<el-dialog :visible.sync="passDialog" title="" top="calc((100vh - 280rem) / 2)" :close-on-click-modal="false">
			<div style="text-align: center; font-size: 16rem; font-weight: bold; color: #333; height: 120rem">通过后教师信息将展示在学生端</div>
			<span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around">
				<el-button type="primary" @click="checkedInfo(1)">确 认</el-button>
				<el-button @click="passDialog = false">取 消</el-button>
			</span>
		</el-dialog>
		<!--    驳回提示弹窗    -->
		<el-dialog :visible.sync="rejectDialog" title="" top="calc((100vh - 450rem) / 2)" class="reject" :close-on-click-modal="false">
			<div style="font-size: 16rem; font-weight: bold; color: #333">请填写不通过原因</div>
			<div>
				<el-form :model="form" ref="form">
					<el-form-item prop="cause" :rules="{ required: true, message: '请输入驳回原因!' }">
						<el-input type="textarea" v-model="form.cause" placeholder="请输入" maxlength="100" show-word-limit></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around">
				<el-button type="primary" @click="checkedInfo(2)">提 交</el-button>
				<el-button @click="rejectDialog = false">取 消</el-button>
			</span>
		</el-dialog>
		<!--  驳回提示弹窗2  -->
		<el-dialog :visible.sync="rejectDialog2" title="" top="calc((100vh - 280rem) / 2)" :close-on-click-modal="false">
			<div style="text-align: center; font-size: 16rem; font-weight: bold; color: #333; height: 120rem">
				驳回后，教师需要重新编辑个人资料，并需要重新审核，确定驳回？
			</div>
			<span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around">
				<el-button
					type="primary"
					@click="
						rejectDialog2 = false
						rejectDialog = true
					"
					>确 认</el-button
				>
				<el-button @click="rejectDialog2 = false">取 消</el-button>
			</span>
		</el-dialog>
		<!--  重复提示弹窗  -->
		<el-dialog :visible.sync="repeat" title="" top="calc((100vh - 280rem) / 2)">
			<div style="text-align: center; font-size: 16rem; font-weight: bold; color: #333; height: 120rem">教师信息已经更改，请重新审核</div>
			<span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around">
				<el-button
					type="primary"
					@click="
						getData()
						repeat = false
					"
					>好 的</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Alert from '@/components/Alert'
import imgUrl from '@/assets/img/photo_boy.png'
import headPortrait from '@/assets/img/avatar_boy.png'
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看","style":0}}},
  data() {
		return {
			passDialog: false,
			rejectDialog: false,
			rejectDialog2: false,
			repeat: false,
			teacherInfo: {},
			passText: {
				pass: '通过后教师信息将展示在学生端',
				conflict: '教师信息已经更改，请重新审核'
			},
			// 驳回原因
			form: {
				cause: null
			},

			id: null,
			state: null,
			imgUrl: imgUrl,
			headPortrait: headPortrait
		}
	},
	components: {
		Alert
	},
	mounted() {
		let { id, type } = this.$route.query
		this.id = id
		this.state = parseInt(type)
		this.getData()
	},
	methods: {
		// 获取初始数据
		getData() {
			let id = this.id
			this.$_axios
				.get('/teacher/details', {
					params: {
						id
					}
				})
				.then(res => {
					let { data } = res.data
					this.teacherInfo = data
				})
		},
		//
		checkedInfo(checked) {
			if (checked === 2) {
				let flag = true
				this.$refs.form.validate(e => {
					flag = e
				})
				if (!flag) return
			}
			this.passDialog = false
			this.rejectDialog = false
			this.rejectDialog2 = false
			this.teacherInfo = Object.assign({}, this.teacherInfo, {
				status: checked,
				failed_reason: this.form.cause || this.teacherInfo.failed_reason
			})
			// return;
			this.$_axios
				.post('/teacher/checked', this.teacherInfo, { isErrorMessage: false })
				.then(res => {
					this.$router.back()
				})
				.catch(err => {
					try {
						let { errorUserMsg, errorCode } = err.data.error
						if (errorCode === 101) this.repeat = true
						else this.$message.error(errorUserMsg)
					} catch (e) {}
				})
		},
		// 驳回
		onReject() {
			let state = this.state
			switch (state) {
				case 0:
					this.rejectDialog = true
					break
				default:
					this.rejectDialog2 = true
					break
			}
		}
	}
}
</script>

<style scoped lang="scss">
.details {
	margin: 0 11.80555555555556vw;
  padding: 24rem 160rem 0;
	.alert {
		width: 512rem;
		height: 54rem;
		margin: 0 auto 24rem;
	}
	.title {
		font-weight: bold;
		color: #333333;
		font-size: 16rem;
	}
	.block {
		position: relative;
		//background: rgba(234, 234, 234, 0.25);
		padding: 15rem 20rem;
		margin: 18rem 0;
		.avatar {
			overflow: hidden;
			position: absolute;
			right: 80rem;
			width: 120rem;
			height: 120rem;
			border-radius: 50%;
			background: #cccccc;
			text-align: center;
			line-height: 120rem;
		}
	}
	.item {
		display: flex;
		margin-bottom: 15rem;
		font-size: 14rem;
		color: #666666;
		.key {
			width: 80rem;
			font-weight: 700;
			text-align: right;
		}
		.val {
			flex: 1;
			margin-left: 25rem;
		}
	}
	.reject {
		::v-deep .el-textarea .el-textarea__inner {
			height: 170rem;
			resize: none;
			margin-top: 40rem;
		}
	}
}
</style>
